<template>
  <div class="container">
    <a-breadcrumb style="padding: 16px 0; background: #f0f2f5">
      <a-breadcrumb-item>系统设置</a-breadcrumb-item>
      <a-breadcrumb-item>菜谱图片上传</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="mainBox" style="padding: 24px">
      <div style="text-align: center">
        <div style="
            width: 100%;
            margin-bottom: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
          ">
          <a-upload name="link_url" :multiple="false" :action="uploadUrl" :headers="headers"
            :showUploadList="showUploadListStatus" @change="handleUploadChange">
            <a-button>
              <a-icon type="upload" /> {{ upload_title }}
            </a-button>
          </a-upload>
          <a-button v-if="menu_img" type="danger" @click="() => remove()" class="e-space">
            删除
          </a-button>
        </div>
        <p>
          <img v-if="menu_img" :src="menu_img" alt srcset />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getEditMenu, editMenu, removeMenu, menuUploadApi } from "@/request/api";
import {
  baseUrl
} from '@/request/http'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    return {
      menu_img: "",
      upload_title: "上传图片（300kb以内）",
      uploadUrl:baseUrl+menuUploadApi,
      headers: {
        Authorization: "Bearer" + " " + localStorage.getItem("token"),
      },
      showUploadListStatus: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getEditData () {
      getEditMenu()
        .then((res) => {
          if (res.data.menu_img) {
            this.menu_img = res.data.menu_img;
            this.upload_title = "替换（300kb以内）";
          }
        })
        .catch((err) => {
          let { errors } = err.data;
          for (let i in errors) {
            this.$message.error(errors[i][0]);
          }
        });
    },
    // 上传文件之前的钩子
    // beforeUpload(file){
    //   let self = this;
    //   return new Promise(function(resolve, reject) {
    //     let filereader = new FileReader();
    //     filereader.onload = e => {
    //       let src = e.target.result;
    //       const image = new Image();
    //       image.onload = function() {
    //         // 获取图片的宽高，并存放到file对象中
    //         file.width = this.width;
    //         file.height = this.height;
    //         resolve();
    //       };
    //       image.onerror = reject;
    //       image.src = src;
    //     };
    //     filereader.readAsDataURL(file);
    //   });
    // },
    handleUploadChange (res) {
      let file = res.file;
      let th = this;
      if ((file.status == "done" || file.status == "error") && file.size > (1024 * 300)) {
        th.$message.info('请上传300kb以下的图片')
        return
      }
      // if(file.status == "done" && (file.width/file.height != 5/4)){
      //   th.$message.info('图片宽高比需为5比4')
      //   return
      // }
      if (!!res.file.response) {
        this.menu_img = res.file.response.path;
        editMenu({ image_url: this.menu_img })
          .then((res) => {
            th.$message.success(res.data);
          })
          .catch((err) => {
            if (err.status == 201) {
              th.$message.success("操作成功");
            } else {
              let { errors } = err.data;
              for (let i in errors) {
                th.$message.error(errors[i][0]);
              }
            }
          });
      }
    },
    remove: function () {
      let th = this;
      this.$confirm({
        title: "操作提示",
        content: "删除之后不可恢复，您确认删除该数据？",
        onOk () {
          removeMenu()
            .then((res) => {
              th.menu_img = "";
              th.$message.success(res.data);
              th.upload_title = '上传图片（300kb以内）'
            })
            .catch((err) => {
              if (err.status == 201) {
                th.menu_img = "";
                th.$message.success("操作成功");
                th.upload_title = '上传图片（300kb以内）'
              } else {
                let { errors } = err.data;
                for (let i in errors) {
                  this.$message.error(errors[i][0]);
                }
              }
            });
        },
        onCancel () { },
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getEditData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.controlBox {
  display: flex;
  .btn {
    margin-bottom: 10px;
    margin-right: 20px;
  }
}
.e-space {
  margin-left: 15px;
}
img {
  width: 70%;
  margin: 0px auto;
}
.ant-upload-list-item.ant-upload-list-item-done {
  display: none;
}
</style>